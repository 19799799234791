<template>
  <div
    v-title
    :data-title="
      gameName + ' - ' + 'Play' + ' ' + gameName + ' Online at gugoplay.com'
    "
  >
    <div
      class="mobile-details"
      :style="playValue ? { display: 'none' } : { display: 'block' }"
      id="mobile-details"
    >
      <StartAndEnd
        :bottomHide="false"
        portal="gugoplay_mobile_detail"
        :gameName="gameName"
      >
        <MobileLogo
          whereFrom="2"
          portal="gugoplay_mobile_detail"
          :gameName="gameName"
        ></MobileLogo>
        <div class="sc-10l37ae-0 eZzVdA">
          <div :class="detailAdv ? hZDmFe2 : hZDmFe">
            <div class="game-name" style="grid-area: ibx">
              <div>
                <h1>{{ gameName }}</h1>
              </div>
              <span class="sc-1ercfrx-6 eyfKaw">{{ gameType }}</span>
            </div>
            <div class="game-play" style="grid-area: bigIp0">
              <div class="game-img-box" @click="playClick">
                <div class="svg"><img :src="play" alt="" /></div>
                <h2>PLAY NOW</h2>
                <img class="img" :src="iconUrl" alt="" />
              </div>
            </div>
            <!-- <div class="adv" v-show="detailAdv" style="grid-area: adv">
              <ins class="adsbygoogle"
                   id="detailAdv"
                   style="display:block;min-height: 314px"
                   data-ad-client="ca-pub-8908317842209223"
                   :data-ad-slot="slot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div> -->
            <router-link
              :to="
                '/M/details/' +
                item.Name.replace(/\s+/g, '') +
                '?gameId=' +
                item.gameId +
                ($route.query.channel ? '&channel=' + $route.query.channel : '')
              "
              class="sc-wr3rvk-0 class-item"
              v-for="(item, index) in gameTypeList"
              :key="index"
            >
              <img v-lazy="item.iconUrl" alt="" />
              <span class="sc-963fcq-0 esaxGV global-cq-title">{{
                item.Name
              }}</span>
            </router-link>
          </div>
        </div>
        <div class="bottom">
          <BottomList whereFrom="2"></BottomList>
          <div>
            <div class="bottom-text">
              <nav class="sc-1oa7ili-0 gmEuRM">
                <ul>
                  <li>
                    <router-link
                      :to="
                        '/P/homeIndex' +
                        ($route.query.channel
                          ? '?channel=' + $route.query.channel
                          : '')
                      "
                      >GAME</router-link
                    >
                  </li>
                  <li>{{ gameType }}</li>
                </ul>
              </nav>
              <header class="sc-1v3c8lr-2 kGpygg">
                <h2 class="sc-1v3c8lr-3 iBLcO">{{ gameName }}</h2>
              </header>
              <div class="sc-1v3c8lr-6 kXVnFE">
                <span class="sc-1v3c8lr-8 bbPKoC"
                  ><i class="el-icon-star-on"></i> {{ gameScore }}</span
                >
              </div>
              <div class="sc-1v3c8lr-9 jXgCKW">
                {{ description ? description : "Play best game on GUGOPLAY!" }}
              </div>
              <ul class="sc-g8xdfn-0 jOvOhG sc-1v3c8lr-4 durvAn">
                <li
                  v-for="(item, index) in typeList"
                  :key="index"
                  @click="classClick(item.type)"
                >
                  {{ item.type }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StartAndEnd>
    </div>
    <div
      class="app-module"
      :style="playValue ? { display: 'block' } : { display: 'none' }"
      v-if="playValue"
    >
      <div class="app-iframe">
        <div class="sc-1nfyi8d-1 kExbnh">
          <div class="iframe-box">
            <iframe
              id="gameIframe"
              :src="playUrl"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div class="app-promote">
            <div class="promote-list">
              <div
                class="item"
                v-for="(item, index) in gameShuffleList"
                :keaddAdsy="index"
              >
                <router-link
                  :to="
                    '/M/details/' +
                    item.Name.replace(/\s+/g, '') +
                    '?gameId=' +
                    item.gameId +
                    ($route.query.channel
                      ? '&channel=' + $route.query.channel
                      : '')
                  "
                  ><img :src="item.iconUrl" alt=""
                /></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="iframe-back"
        @click="backClick"
        :style="mobileNavDragY"
        @touchmove="backToucheMove"
      >
        <img :src="goBack" alt="" />
      </div>
      <!-- <router-link class="tap-game" :style="mobileTapY"  @touchmove="tapToucheMove" v-for="(item,index) in tapGameList" :key="index" :to="'/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img class="img-tap-game" :src="item.iconUrl" alt=""></router-link> -->
    </div>
    <!--    <div class="is-top" :style="isTop ? {display: 'block'} : {display: 'none'}" @click="isTopClick">-->
    <!--      <img :src="topping" alt="">-->
    <!--    </div>-->

    <div style="display: contents" v-if="smegmaType">
      <div class="AFSJx dgmlwU" style="background-color: #83ffe7">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import goBack from "@/assets/goBack.png";
import topping from "@/assets/topping.png";
import play from "@/assets/play.png";
import MobileLogo from "@/components/MobileLogo.vue";
import BottomList from "@/components/MobileTerminal/MobileHome/BottomList";
import StartAndEnd from "@/components/MobileTerminal/MobileHome/StartAndEnd";
import {
  shuffle,
  determinePcOrMove,
  setMeta,
  getJson,
  recentGame,
  getGameTypeList,
  pageOutLog,
  clickGameLog,
  pageInitLog,
  Observer,
  detailsPageInitLog,
  detailsPageOutLog,
} from "@/utils/utils";
import { show_newAfg_preroll } from "../../../../webh5sdk";
export default {
  name: "mobileDetailsIndex",
  components: {
    StartAndEnd,
    BottomList,
    MobileLogo,
  },
  data() {
    return {
      tapGameList: [], // 闪标列表
      gameName: "", // 游戏名称
      gameType: "", // 游戏类型
      iconUrl: "", // 游戏icon
      description: "", // 游戏简介
      playUrl: "", // 游戏url
      typeList: [], // 游戏分类
      gameTypeList: [], // 游戏列表
      gameShuffleList: [], // 随机列表
      playValue: false,
      playValue1: false,
      isTop: false,
      timer: null, // 定时器
      goBack,
      topping,
      play,
      gameScore: "", // 游戏评分
      detailAdv: false, // 是否展示广告位置
      hZDmFe2: "hZDmFe2", // 有广告样式
      hZDmFe: "hZDmFe", // 无广告样式
      smegmaType: false, // 蒙层状态
      mobileNavDragY: "--mobileNavDragY: 24px",
      mobileTapY: "--mobileTapY: 150px",
      clientY: null, // 拖拽初始位置,
      slot: null,
    };
  },
  created() {
    let channelInfo =
      localStorage.getItem("channelInfo") &&
      JSON.parse(localStorage.getItem("channelInfo"));
    const { m_dtl_slot } = channelInfo || {};
    this.slot = m_dtl_slot ? m_dtl_slot : "5560469002";
  },
  mounted() {
    console.log(window.name);
    if (window.name == "") {
      window.name = "isReload";
    } else if (window.name == "isReload" || window.name == "play") {
      console.log("刷新页面");
      console.log(window.name);
      const { params } = this.$route;
      const { gameName } = params || {};
      if (window.name == "play") {
        // 离开页面埋点
        pageOutLog("gugoplay_mobile_detail");
        detailsPageOutLog("gugoplay_mobile_detail", gameName);
      } else {
        pageOutLog("gugoplay_mobile_detail");
      }
    }
    window.addEventListener("unload", this.handleWindowUnload);
    const { query, params } = this.$route;
    const { gameId } = query;
    const { gameName } = params || {};
    console.log(gameName);
    if (determinePcOrMove() == 2) {
      let { channel } = this.$route.query;
      this.$router.push(
        {
          path: `/P/details${gameName ? "/" + gameName : ""}`,
          query: {
            gameId,
            channel,
          },
        },
        () => {}
      );
    } else {
      // 获取需要曝光的item
      setTimeout(() => {
        let itemArr = [...document.getElementsByClassName("sc-wr3rvk-0")];
        itemArr &&
          Array.from(itemArr).map((item) => {
            Observer("gugoplay_mobile_detail").observe(item);
          });
      });
      // 蒙层状态
      this.smegmaType = true;
      setTimeout(() => {
        this.smegmaType = false;
      }, 800);
      setTimeout(() => {
        // window.addAds()
        let innerHtml =
          document.getElementById("detailAdv") &&
          document.getElementById("detailAdv").innerHTML;
        if (innerHtml) {
          this.detailAdv = true;
        } else {
          this.detailAdv = false;
        }
      }, 800);
      document
        .getElementById("mobile-details")
        .addEventListener("scroll", this.handleScroll, true);
      this.getJson();
    }
  },
  methods: {
    getJson() {
      this.typeList = getGameTypeList() || [];

      // 游戏评分
      let score = Math.random() * 0.8 + 4.2;
      this.gameScore = score.toFixed(1);

      const { query } = this.$route;
      const { gameId, channel } = query || {};
      let arr = getJson() || [];
      let newArr = [];
      let gameInfo = {};
      arr.map((item) => {
        if (item.gameId == gameId) {
          gameInfo = item;
        }
        newArr.push(item);
      });
      this.gameName = gameInfo.Name;
      this.gameType = gameInfo.Type;
      this.iconUrl = gameInfo.iconUrl;
      this.description = gameInfo.desc;
      // 渠道
      if (channel) {
        gameInfo.Url = gameInfo.Url + `?channel=` + channel + "-M";
      }
      this.playUrl = gameInfo.Url;
      this.gameTypeList = shuffle(newArr).splice(0, 30);
      // 进入页面埋点
      pageInitLog("gugoplay_mobile_detail");
      setMeta(
        `${gameInfo.Name},${gameInfo.Name} Online,${gameInfo.Name} for free`,
        `${gameInfo.Name} is a Games`
      );
    },

    // 跳转分类
    classClick(gameType) {
      let { channel } = this.$route.query;
      this.$router.push(
        {
          path: "/M/gameType",
          query: {
            gameType,
            channel,
          },
        },
        () => {}
      );
    },
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop = document.getElementById("mobile-details").scrollTop;
      // console.log(scrollTop);
      if (scrollTop > 0) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    },
    // 置顶
    isTopClick() {
      document.getElementById("mobile-details").scrollTop = 0;
    },
    // 开始游戏
    playClick() {
      window.name = "play";
      // 进入页面埋点
      detailsPageInitLog("gugoplay_mobile_detail", this.gameName);
      // 广告
      show_newAfg_preroll().show_newAfg_preroll();
      // 打开全屏
      const { documentElement } = document;
      if (documentElement.requestFullscreen) {
        documentElement.requestFullscreen();
      } else if (documentElement.mozRequestFullScreen) {
        documentElement.mozRequestFullScreen();
      } else if (documentElement.webkitRequestFullScreen) {
        documentElement.webkitRequestFullScreen();
      }
      // 初始化图标位置
      this.mobileNavDragY = `--mobileNavDragY: 24px`;
      this.mobileTapY = "--mobileTapY: 150px";

      this.playValue = true;
      let arr = [];
      this.gameTypeList.map((item) => {
        arr.push(item);
      });
      let shuffle30 = shuffle(arr).splice(0, 30) || [];
      this.gameShuffleList = shuffle30.splice(0, 5);
      this.tapGameList = shuffle30.splice(0, 1);
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let newArr = [];
        this.gameTypeList.map((item) => {
          newArr.push(item);
        });
        let shuffleArr = shuffle(newArr).splice(0, 30) || [];
        this.gameShuffleList = shuffleArr.splice(0, 5);
      }, 10000);
      clearInterval(this.timer2);
      this.timer2 = setInterval(() => {
        let newArr = [];
        this.gameTypeList.map((item) => {
          newArr.push(item);
        });
        let shuffleArr = shuffle(newArr).splice(0, 30) || [];
        this.tapGameList = shuffleArr.splice(0, 1);
      }, 6000);
    },
    // 退出全屏
    exitFullscreen() {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    // 返回
    backClick() {
      detailsPageOutLog("gugoplay_mobile_detail", this.gameName);
      window.name = "isReload";
      window.removeEventListener("unload", this.handleWindowUnload);
      this.exitFullscreen();
      this.playValue = false;
      clearInterval(this.timer);
      clearInterval(this.timer2);
    },
    // 鼠标拖动返回按钮
    backToucheMove(e) {
      e.preventDefault();
      this.mobileNavDragY = `--mobileNavDragY: ${
        e.targetTouches[0].clientY - 17 > 24
          ? e.targetTouches[0].clientY - 17 > window.innerHeight - 45
            ? window.innerHeight - 45
            : e.targetTouches[0].clientY - 17
          : 24
      }px`;
    },
    // 鼠标拖动返回按钮
    tapToucheMove(e) {
      e.preventDefault();
      this.mobileTapY = `--mobileTapY: ${
        e.targetTouches[0].clientY - 25 > 150
          ? e.targetTouches[0].clientY - 25 > window.innerHeight - 50
            ? window.innerHeight - 50
            : e.targetTouches[0].clientY - 25
          : 150
      }px`;
    },
    handleWindowUnload() {
      detailsPageOutLog("gugoplay_mobile_detail", this.gameName);
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    console.log(from);
    window.name = "";
    next();
  },
  beforeDestroy() {
    // 离开页面埋点
    pageOutLog("gugoplay_mobile_detail");
    if (this.playValue) {
      detailsPageOutLog("gugoplay_mobile_detail", this.gameName);
    }
  },
  destroyed() {
    window.removeEventListener("unload", this.handleWindowUnload);
  },
  watch: {
    $route(val, old) {
      if (window.location.href.split("#")[1]) {
      } else {
        console.log(val, old, "数据更新了");
        if (this.playValue) {
          this.exitFullscreen();
          if (old.query.gameId) {
            getJson().map((item) => {
              if (item.gameId == old.query.gameId) {
                detailsPageOutLog(
                  "gugoplay_mobile_detail",
                  old.params.gameName
                );
              }
            });
          }
        }
        if (val.query.gameId) {
          getJson().map((item) => {
            if (item.gameId == val.query.gameId) {
              // 打点
              clickGameLog("gugoplay_mobile_detail", item);
              recentGame(item);
              clearInterval(this.timer);
              clearInterval(this.timer2);
              this.playValue = false;
            }
          });
        }

        // 离开页面埋点
        pageOutLog("gugoplay_mobile_detail");
        document.getElementById("mobile-details").scrollTop = 0;
        // 蒙层状态
        this.smegmaType = true;
        setTimeout(() => {
          this.smegmaType = false;
        }, 800);
        this.getJson();
        window.name = "isReload";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (hover: hover) {
  .class-item:hover {
    transform: scale(1.01869) translate(0px, -4px) !important;
  }
  .class-item:hover::after {
    opacity: 1 !important;
    background: linear-gradient(
      transparent 50%,
      rgba(0, 0, 0, 0.3) 100%
    ) !important;
  }
  .class-item:hover .sc-963fcq-0 {
    opacity: 1 !important;
    transform: translate(0px, 0px) !important;
  }
}
@media (min-width: 111px) and (max-width: 550.9px) {
  .eZzVdA,
  .bottom {
    width: 314px;
  }
  .hZDmFe2 {
    grid-template-areas:
      ". ibx ibx"
      "bigIp0 bigIp0 bigIp0"
      "bigIp0 bigIp0 bigIp0"
      "bigIp0 bigIp0 bigIp0"
      "adv adv adv"
      "adv adv adv"
      "adv adv adv";
  }
  .hZDmFe {
    grid-template-areas:
      ". ibx ibx"
      "bigIp0 bigIp0 bigIp0"
      "bigIp0 bigIp0 bigIp0"
      "bigIp0 bigIp0 bigIp0"
      ". . ."
      ". . .";
  }
}
@media (min-width: 111px) and (max-width: 770.9px) {
  .game-play {
    height: 314px;
  }
}
@media (min-width: 551px) and (max-width: 660.9px) {
  .game-name {
    display: none !important;
  }
  .eZzVdA,
  .bottom {
    width: 534px;
  }
  .hZDmFe2 {
    --gridTemplateColumns: 5 !important;
    grid-template-areas:
      ". bigIp0 bigIp0 bigIp0 ."
      ". bigIp0 bigIp0 bigIp0 ."
      ". bigIp0 bigIp0 bigIp0 ."
      "adv adv adv adv adv"
      "adv adv adv adv adv"
      "adv adv adv adv adv"
      ". . . . ."
      ". . . . .";
  }
  .hZDmFe {
    --gridTemplateColumns: 5 !important;
    grid-template-areas:
      ". bigIp0 bigIp0 bigIp0 ."
      ". bigIp0 bigIp0 bigIp0 ."
      ". bigIp0 bigIp0 bigIp0 ."
      ". . . . ."
      ". . . . ."
      ". . . . ."
      ". . . . .";
  }
}
@media (min-width: 661px) and (max-width: 880.9px) {
  .eZzVdA,
  .bottom {
    width: 644px;
  }
  .hZDmFe2 {
    --gridTemplateColumns: 6 !important;
    grid-template-areas:
      ". ibx ibx ibx . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . ."
      "adv adv adv adv adv adv"
      "adv adv adv adv adv adv"
      "adv adv adv adv adv adv";
  }
  .hZDmFe {
    --gridTemplateColumns: 6 !important;
    grid-template-areas:
      ". ibx ibx ibx . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . .";
  }
}
@media (min-width: 661px) {
  .game-play {
    height: 424px;
  }
}
@media (min-width: 881px) {
  .eZzVdA,
  .bottom {
    width: 864px;
  }
  .hZDmFe2 {
    --gridTemplateColumns: 8 !important;
    grid-template-areas:
      ". ibx ibx ibx . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . ."
      "adv adv adv adv adv adv adv adv"
      "adv adv adv adv adv adv adv adv"
      "adv adv adv adv adv adv adv adv";
  }
  .hZDmFe {
    --gridTemplateColumns: 8 !important;
    grid-template-areas:
      ". ibx ibx ibx . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . ."
      "bigIp0 bigIp0 bigIp0 bigIp0 . . . .";
  }
}
.mobile-details {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 3;
  //background-color: #0054ff;
  .eZzVdA {
    margin: 0px auto;
    flex-grow: 1;
  }
  .hZDmFe,
  .hZDmFe2 {
    display: grid;
    grid-template-rows: repeat(auto-fill, 94px);
    grid-gap: 16px;
    grid-auto-flow: dense;
    justify-content: center;
    margin: 16px auto 0px;
    padding: 0px;
    list-style-type: none;
    --gridTemplateColumns: 3;
    grid-template-columns: repeat(var(--gridTemplateColumns), 94px);
  }
  .hZDmFe::before {
    content: "";
    display: block;
  }
  .hZDmFe2::before {
    content: "";
    display: block;
  }
  .game-name {
    background: white;
    border-radius: 16px;
    padding: 10px 16px;
    box-sizing: border-box;
    word-break: break-all;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    align-items: flex-start;
    font-size: 21px;
    h1 {
      font: 500 20px/24px Torus, sans-serif;
      color: #002b50;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .eyfKaw {
      display: block;
      color: #5d6b84;
      font-size: 12px;
      line-height: 15px;
      margin: 5px 0px 0px;
      letter-spacing: 0.3px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  .game-play {
    display: flex;
    justify-content: center;
    .game-img-box {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
      position: relative;
      cursor: pointer;
      .svg {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        border-radius: 100px;
        background-color: rgb(255, 255, 255);
        height: 64px;
        width: 64px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
        transform: translate(-50%, -50%);
        animation: 4s ease-in-out 0s infinite normal both running scaleDelay;
        box-sizing: border-box;
        font-size: 0;
        img {
          width: 24px;
          height: 24px;
        }
      }
      h2 {
        color: rgb(255, 255, 255);
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: 40px;
        z-index: 2;
        text-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
        transform: translateX(-50%);
        word-break: break-all;
        font-size: 28px;
      }
      .img {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0px;
        left: 0px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.5);
        object-fit: contain;
      }
    }
    .game-img-box::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: 0px;
      bottom: 0px;
      left: 0px;
      height: 100%;
      border-radius: 16px;
      background: linear-gradient(
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 0.3) 100%
      );
    }
  }
  .class-item {
    float: left;
    width: 94px;
    height: 94px;
    display: block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 0;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
  .class-item::after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 5;
    transition: box-shadow 0.6s cubic-bezier(0.25, 0.1, 0.25, 1),
      opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    border-radius: 16px;
    contain: strict;
  }
  .esaxGV {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding: 6px;
    color: rgb(255, 255, 255);
    font: 700 var(--tileFontSize, 12px) / 1.3 "Proxima Nova", sans-serif;
    text-align: center;
    z-index: 6;
    transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0.1s,
      opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0.1s;
    transform: translate(0px, 8px);
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
  }
  .adv {
    margin: 0 auto;
  }
  .bottom {
    margin: 0 auto;
  }
  .bottom-text {
    position: relative;
    margin: 24px 0px;
    padding: 18px 24px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    .gmEuRM ul {
      overflow: hidden;
      margin: 0px;
      padding: 0px;
      color: var(--grey-1);
      list-style: none;
      text-overflow: ellipsis;
    }
    .gmEuRM li {
      display: inline;
      color: #5d6b84;
      font-size: 16px;
      a {
        text-decoration: none;
        color: #5d6b84;
      }
    }
    .gmEuRM li:nth-of-type(2)::before {
      content: "›";
      margin: 0px 4px;
      color: var(--grey-1);
      font-size: 13px;
    }
    .kGpygg {
      margin: 8px 0px 8px;
      h2 {
        margin: 0px;
        font: 500 24px Torus, sans-serif;
        color: #002b50;
      }
    }
    .kXVnFE {
      margin: 4px 0px;
      .bbPKoC {
        display: inline-block;
        font-size: 12px;
        line-height: 22px;
        font-weight: bold;
        padding: 0px 8px;
        color: #5d6b84;
        background: #f0f5fc;
        border-radius: 10px;
        text-transform: uppercase;
        height: 20px;
        margin-right: 4px;
      }
    }
    .jXgCKW {
      color: #002b50;
      font: 400 16px/24px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans,
        Arial, sans-serif;
    }
    .durvAn {
      margin-top: 12px;
      margin-bottom: 8px;
    }
    .jOvOhG li {
      display: inline-block;
      margin: 4px 4px 0px 0px;
      padding: 0px 10px;
      border: 2px solid #bac9de;
      border-radius: 100px;
      color: #bac9de;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
@supports (height: 100dvh) {
  .app-module {
    --minHeight: 100dvh;
    --height: 100dvh;
  }
}
.app-module {
  position: absolute;
  left: 0;
  top: 0;
  height: var(--height, 100%);
  width: var(--width, 100%);
  --width: 100vw;
  min-height: var(--minHeight, 100vh);
  z-index: 5;
  background-color: #127dab;
  overflow: hidden;
  .app-iframe {
    display: flex;
    justify-content: center;
    margin: 0px auto;
    position: relative;
    z-index: 1;
    width: 100% !important;
    height: 100% !important;
    //width: 100%;
    //height: calc(100vh - 4.375rem);
    .kExbnh {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 0;
      width: 100%;
      height: 100%;
      background: var(--denim-blue);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
      .iframe-box {
        width: 100%;
        height: 100%;
        padding: 0 2px 2px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        position: relative;
        #gameIframe {
          border: 1px solid #cccccc;
        }
      }
    }
  }
}
@keyframes djqRmU {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes etkMLf {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.dgmlwU {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: djqRmU 0.2s cubic-bezier(0.25, 0.1, 0.25, 1) both;
  z-index: 100001;
}
.AFSJx {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 18px;
    height: 18px;
    margin: 0 2px;
    border-radius: 100%;
    background: white;
    animation: 1.4s ease-in-out 0s infinite normal both running etkMLf;
  }
  div:nth-child(2) {
    animation-delay: -0.16s;
  }
}
.is-top {
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  position: fixed;
  bottom: 5.375rem;
  right: 0.625rem;
  box-shadow: 0.125rem 0 0.3125rem rgb(0 0 0/30%);
  border-radius: 50%;
  //background: #ffffff;
  z-index: 3;
  text-align: center;
  /deep/.el-icon-top {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (orientation: portrait) {
  .app-iframe {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .iframe-back {
    z-index: 1;
    position: fixed;
    top: 0;
    border-radius: 0 1.125rem 1.125rem 0;
    overflow: hidden;
    width: 70px;
    height: 45px;
    text-align: center;
    transform: translate(0, var(--mobileNavDragY, 24px));
    /deep/ .el-icon-arrow-left {
      font-size: 2rem;
      color: #ffffff;
      line-height: 2.1875rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tap-game {
    .img-tap-game {
      left: 0;
      z-index: 110;
      top: 0;
      height: 50px;
      width: 50px;
      position: fixed;
      -webkit-border-radius: 25px;
      border-radius: 25px;
      opacity: 0.1;
      overflow: hidden;
      animation-name: breath;
      animation-duration: 1200ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      -webkit-animation-name: breath;
      -webkit-animation-duration: 1200ms;
      -webkit-animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: infinite;
      transform: translate(0, var(--mobileTapY, 150px));
    }
  }
  @keyframes breath {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
  .app-promote {
    height: 4.475rem;
    overflow: hidden;
    background-color: #127dab;
    padding: 0.5rem 0;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }
  .app-promote .promote-list {
    white-space: nowrap;
    overflow: hidden;
    font-size: 0;
  }
  .app-promote .promote-list .item {
    display: inline-block;
    width: 3.5rem;
    height: 3.5rem;
    margin-left: 0.42rem;
    border-radius: 0.875rem;
    color: #333;
    overflow: hidden;
    background-color: #e4e4e4;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (orientation: landscape) {
  .app-module {
    display: flex !important;
    flex-direction: row-reverse;
  }
  .kExbnh {
    flex-direction: row-reverse !important;
  }
  .app-iframe {
    width: calc(100vw - 4.375rem);
    height: 100% !important;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .iframe-back {
    z-index: 1;
    text-align: center;
    position: fixed;
    top: 0;
    left: 2.15rem;
    border-radius: 0 0.6429rem 0.6429rem 0;
    overflow: hidden;
    width: 70px;
    height: 45px;
    transform: translate(0, var(--mobileNavDragY, 24px));
    /deep/ .el-icon-arrow-left {
      font-size: 1rem;
      color: #ffffff;
      line-height: 1rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tap-game {
    .img-tap-game {
      left: 2.171831rem;
      z-index: 110;
      top: 0;
      height: 50px;
      width: 50px;
      position: fixed;
      -webkit-border-radius: 25px;
      border-radius: 25px;
      opacity: 0.1;
      overflow: hidden;
      animation-name: breath;
      animation-duration: 1200ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      -webkit-animation-name: breath;
      -webkit-animation-duration: 1200ms;
      -webkit-animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: infinite;
      transform: translate(0, var(--mobileTapY, 150px));
    }
  }
  @keyframes breath {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
  .app-promote {
    height: 100%;
    width: 2.21rem;
    overflow: hidden;
    background-color: #127dab;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    position: relative;
    z-index: 3;
    padding: 0 0.3rem;
    box-sizing: border-box;
  }
  .app-promote .promote-list {
    height: 100%;
    overflow: hidden;
  }
  .app-promote .promote-list .item {
    width: 1.571831rem;
    height: 1.571831rem;
    margin: 0.23rem auto 0;
    display: block;
    border-radius: 0.492958rem;
    overflow: hidden;
    font-size: 0.422535rem;
    color: #333;
    background-color: #e4e4e4;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
