var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"title",rawName:"v-title"}],attrs:{"data-title":_vm.gameName + ' - ' + 'Play' + ' ' + _vm.gameName + ' Online at gugoplay.com'}},[_c('div',{staticClass:"mobile-details",style:(_vm.playValue ? { display: 'none' } : { display: 'block' }),attrs:{"id":"mobile-details"}},[_c('StartAndEnd',{attrs:{"bottomHide":false,"portal":"gugoplay_mobile_detail","gameName":_vm.gameName}},[_c('MobileLogo',{attrs:{"whereFrom":"2","portal":"gugoplay_mobile_detail","gameName":_vm.gameName}}),_c('div',{staticClass:"sc-10l37ae-0 eZzVdA"},[_c('div',{class:_vm.detailAdv ? _vm.hZDmFe2 : _vm.hZDmFe},[_c('div',{staticClass:"game-name",staticStyle:{"grid-area":"ibx"}},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.gameName))])]),_c('span',{staticClass:"sc-1ercfrx-6 eyfKaw"},[_vm._v(_vm._s(_vm.gameType))])]),_c('div',{staticClass:"game-play",staticStyle:{"grid-area":"bigIp0"}},[_c('div',{staticClass:"game-img-box",on:{"click":_vm.playClick}},[_c('div',{staticClass:"svg"},[_c('img',{attrs:{"src":_vm.play,"alt":""}})]),_c('h2',[_vm._v("PLAY NOW")]),_c('img',{staticClass:"img",attrs:{"src":_vm.iconUrl,"alt":""}})])]),_vm._l((_vm.gameTypeList),function(item,index){return _c('router-link',{key:index,staticClass:"sc-wr3rvk-0 class-item",attrs:{"to":'/M/details/' +
              item.Name.replace(/\s+/g, '') +
              '?gameId=' +
              item.gameId +
              (_vm.$route.query.channel ? '&channel=' + _vm.$route.query.channel : '')}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.iconUrl),expression:"item.iconUrl"}],attrs:{"alt":""}}),_c('span',{staticClass:"sc-963fcq-0 esaxGV global-cq-title"},[_vm._v(_vm._s(item.Name))])])})],2)]),_c('div',{staticClass:"bottom"},[_c('BottomList',{attrs:{"whereFrom":"2"}}),_c('div',[_c('div',{staticClass:"bottom-text"},[_c('nav',{staticClass:"sc-1oa7ili-0 gmEuRM"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":'/P/homeIndex' +
                      (_vm.$route.query.channel
                        ? '?channel=' + _vm.$route.query.channel
                        : '')}},[_vm._v("GAME")])],1),_c('li',[_vm._v(_vm._s(_vm.gameType))])])]),_c('header',{staticClass:"sc-1v3c8lr-2 kGpygg"},[_c('h2',{staticClass:"sc-1v3c8lr-3 iBLcO"},[_vm._v(_vm._s(_vm.gameName))])]),_c('div',{staticClass:"sc-1v3c8lr-6 kXVnFE"},[_c('span',{staticClass:"sc-1v3c8lr-8 bbPKoC"},[_c('i',{staticClass:"el-icon-star-on"}),_vm._v(" "+_vm._s(_vm.gameScore))])]),_c('div',{staticClass:"sc-1v3c8lr-9 jXgCKW"},[_vm._v(" "+_vm._s(_vm.description ? _vm.description : "Play best game on GUGOPLAY!")+" ")]),_c('ul',{staticClass:"sc-g8xdfn-0 jOvOhG sc-1v3c8lr-4 durvAn"},_vm._l((_vm.typeList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.classClick(item.type)}}},[_vm._v(" "+_vm._s(item.type)+" ")])}),0)])])],1)],1)],1),(_vm.playValue)?_c('div',{staticClass:"app-module",style:(_vm.playValue ? { display: 'block' } : { display: 'none' })},[_c('div',{staticClass:"app-iframe"},[_c('div',{staticClass:"sc-1nfyi8d-1 kExbnh"},[_c('div',{staticClass:"iframe-box"},[_c('iframe',{attrs:{"id":"gameIframe","src":_vm.playUrl,"width":"100%","height":"100%"}})]),_c('div',{staticClass:"app-promote"},[_c('div',{staticClass:"promote-list"},_vm._l((_vm.gameShuffleList),function(item,index){return _c('div',{staticClass:"item",attrs:{"keaddAdsy":index}},[_c('router-link',{attrs:{"to":'/M/details/' +
                  item.Name.replace(/\s+/g, '') +
                  '?gameId=' +
                  item.gameId +
                  (_vm.$route.query.channel
                    ? '&channel=' + _vm.$route.query.channel
                    : '')}},[_c('img',{attrs:{"src":item.iconUrl,"alt":""}})])],1)}),0)])])]),_c('div',{staticClass:"iframe-back",style:(_vm.mobileNavDragY),on:{"click":_vm.backClick,"touchmove":_vm.backToucheMove}},[_c('img',{attrs:{"src":_vm.goBack,"alt":""}})])]):_vm._e(),(_vm.smegmaType)?_c('div',{staticStyle:{"display":"contents"}},[_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AFSJx dgmlwU",staticStyle:{"background-color":"#83ffe7"}},[_c('div'),_c('div'),_c('div')])
}]

export { render, staticRenderFns }